'use strict';

$(function(){
    var switches = $('.section-switch'),
        sections = $('.product-info-section'),
        first_switch = switches.first();

    var getSwitchSection = function(sw){
        return $($('.section-link', sw).attr('href'));
    };

    first_switch.addClass('current');
    sections.addClass('disabled');
    getSwitchSection(first_switch).removeClass('disabled');

    switches.each(function(){
        var that = $(this),
            link = $('.section-link', that),
            section = $(link.attr('href'));

        link.on('click', function(ev){
            ev.preventDefault();
            switches.removeClass('current');
            that.addClass('current');
            sections.addClass('disabled');
            section.removeClass('disabled');
        });
    });
});